{
  "name": "ringcentral-web-phone",
  "version": "1.0.0-beta3",
  "homepage": "https://github.com/ringcentral/ringcentral-web-phone",
  "bugs": {
    "url": "https://github.com/ringcentral/ringcentral-web-phone/issues"
  },
  "repository": {
    "type": "git",
    "url": "git://github.com/ringcentral/ringcentral-web-phone.git"
  },
  "license": "MIT",
  "author": {
    "name": "RingCentral, Inc.",
    "email": "devsupport@ringcentral.com"
  },
  "contributors": [
    {
      "name": "Kirill Konshin"
    },
    {
      "name": "Elias Sun"
    },
    {
      "name": "Vyshakh Babji"
    },
    {
      "name": "Yatin Gera"
    },
    {
      "name": "Tyler Liu"
    },
    {
      "name": "Embbnux Ji"
    }
  ],
  "main": "./lib/src/index.js",
  "types": "./lib/src/index.d.ts",
  "scripts": {
    "build": "rm -rf lib && yarn tsc && rm -rf docs && parcel build demo/index.html demo/callback.html --dist-dir docs --public-url ./",
    "lint": "eslint --fix '**/*.{ts,tsx,js,jsx}' && prettier --write . && sort-package-json",
    "serve": "parcel demo/index.html demo/callback.html --dist-dir docs --port 5500",
    "test": "jest",
    "test:coverage": "cat .coverage/lcov.info | coveralls -v"
  },
  "dependencies": {
    "sip.js": "0.21.2"
  },
  "devDependencies": {
    "@faker-js/faker": "8.0.1",
    "@ringcentral/sdk": "4.7.4",
    "@types/jest": "29.5.1",
    "@types/jest-environment-puppeteer": "5.0.3",
    "@types/node": "20.2.3",
    "@typescript-eslint/eslint-plugin": "5.59.7",
    "@typescript-eslint/parser": "5.59.7",
    "bootstrap": "3.4.1",
    "buffer": "6.0.3",
    "coveralls": "3.1.1",
    "crypto-browserify": "3.12.0",
    "dotenv-override-true": "6.2.2",
    "eslint": "8.41.0",
    "eslint-config-alloy": "5.0.0",
    "eslint-config-prettier": "8.8.0",
    "eslint-plugin-jest": "27.2.1",
    "eslint-plugin-prettier": "4.2.1",
    "events": "^3.1.0",
    "http-server": "^14.1.1",
    "jest": "29.5.0",
    "jest-puppeteer": "8.0.6",
    "jquery": "3.7.0",
    "parcel": "^2.8.3",
    "prettier": "2.8.8",
    "process": "^0.11.10",
    "puppeteer": "20.3.0",
    "querystring-es3": "0.2.1",
    "sort-package-json": "2.4.1",
    "stream-browserify": "3.0.0",
    "ts-jest": "29.1.0",
    "ts-node": "10.9.1",
    "ttpt": "0.6.3",
    "typescript": "5.0.4",
    "wait-for-async": "0.6.1",
    "yarn-upgrade-all": "0.7.2"
  },
  "preferGlobal": false,
  "targets": {
    "main": false,
    "module": false,
    "types": false,
    "browser": false
  }
}
